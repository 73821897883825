<template>
  <div>
    <template v-if="!loading">
      <visual-rank-item-card v-for="row in data" :row="row" :key="row.idPrice" @click="selectProduct(row)" @change="onChange" :checked="values.includes(row.idPrice)" />
    </template>
    <template v-else>
      <span v-for="i in 20" :key="i" class="h-20 rounded-lg border-2 border-white border-solid mb-1 skeleton bg-secondary-100 w-full block"></span>
    </template>
  </div>
</template>

<script>
import VisualRankItemCard from '@/components/VisualRankItemCard.vue'

export default {
  name: 'VisualRankCardView',
  components: {
    VisualRankItemCard
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      values: this.value
    }
  },
  methods: {
    selectProduct: function (product) {
      this.$emit('selected', product)
    },
    onChange: function (value, checked) {
      if (checked) {
        this.values.push(value)
      } else {
        this.values = this.values.filter(v => v !== value)
      }

      this.$emit('input', this.values)
    }
  },
  watch: {
    value: function (value) {
      this.values = value
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
