<template>
  <div>
    <table v-if="!loading">
      <thead>
        <tr>
          <th></th>
          <th class="brand-max-width">{{ $t('app.brand') }}</th>
          <th>{{ $t('app.model') }}</th>
          <th v-for="(dimension, index) in dimensions" :key="index">{{ dimension.name }}</th>
          <th class="position">{{ $t('app.position') }}</th>
          <th class="price">{{ $t('app.price') }}</th>
          <th class="ivr-max-width">{{ $t('app.ivr') }}</th>
        </tr>
      </thead>
      <tbody>
        <table-row-visual-rank v-for="row in data" :row="row" :key="row.idPrice" @click="selectProduct(row)" @change="onChange" :checked="values.includes(row.idPrice)" />
      </tbody>
    </table>
    <template v-else>
      <span v-for="i in 20" :key="i" class="h-10 rounded-lg border-2 border-white border-solid mb-1 skeleton bg-secondary-100 w-full block"></span>
    </template>
  </div>
</template>

<script>
import TableRowVisualRank from '@/components/tables/TableRowVisualRank.vue'
export default {
  name: 'TableVisualRank',
  components: {
    TableRowVisualRank
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      values: this.value
    }
  },
  computed: {
    locale: function () {
      return this.$store.getters['auth/locale']
    },
    dimensions: function () {
      const locale = this.locale
      return this.$store.getters['filters/names'](locale)
    }
  },
  methods: {
    selectProduct: function (product) {
      this.$emit('selected', product)
    },
    onChange: function (value, checked) {
      if (checked) {
        this.values.push(value)
      } else {
        this.values = this.values.filter(v => v !== value)
      }

      this.$emit('input', this.values)
    }
  },
  watch: {
    value: function (value) {
      this.values = value
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border: none;
  position: relative;
  // overflow: hidden;
  background-color: transparent;
  border-collapse: separate;
  border-spacing: 0 4px;
  margin-top: -4px; /* correct offset on first border spacing if desired */
}

thead {
  tr th {
    @apply text-secondary-500;
    z-index: 20;
    padding: 4px 8px;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 500;
    text-align: left;
  }
}

th {
  @apply sticky top-0 z-20 bg-primary-body;
}

.skeleton {
  position: relative;
  overflow: hidden;
  background-color: #e2e8f0;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

</style>
