import axiosInstance from '@/services/axiosInstance'

async function getVisualRank (groupId, categoryId, siteId, startDate, endDate, data) {
  const req = {
    url: `visualRank/${groupId}/${categoryId}/${siteId}/${startDate}/${endDate}`,
    method: 'POST',
    data
  }

  const res = await axiosInstance(req)

  return res.data
}

export { getVisualRank }
