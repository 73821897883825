<template>
  <full-modal-layout :isOpen="toggle">
    <div v-if="toggle && !loading" class="flex flex-col h-full">
      <header class="flex-shrink-0 flex flex-col items-center justify-center py-2 text-sm relative bg-secondary text-white">
        <h3 class="font-bold leading-tight">{{ $t('app.analyzeCompetition') }} &middot; <span class="text-xs">{{ $tc('app.selectedItemsCounter', value.length) }}</span></h3>
        <h4 class="opacity-50 font-semibold hidden">{{ $tc('app.selectedProductsCounter', value.length) }}</h4>
        <h4 class="opacity-80 font-semibold">
          {{ $t('app.priceEvolutionAndPosition') }}  {{ $t('app.fromTo', { start: transformDate(startDate), end: transformDate(endDate) }) }}
        </h4>
        <base-button-icon
          @click="toggleModal"
          name="cross"
          size="xs"
          iconSize="xs"
          mode="soft"
          custom="absolute right-2 top-2"
        />
      </header>
      <app-compare-highcharts v-if="anyChartResult(data)" :data="data" :categories="brands" :colors="colors"></app-compare-highcharts>
      <template v-else-if="touch">
        <base-not-found />
      </template>
    </div>
  </full-modal-layout>
</template>

<script>
import FullModalLayout from '@/layouts/FullModalLayout.vue'
import { getCalendarPresets } from '@/utils/calendar'
import moment from 'moment'
import { getCompetition } from '@/services/visual-rank/getCompetition'
import AppCompareHighcharts from '@/components/charts/AppCompareHighcharts'
import { getColors } from '@/utils/colors'
import { transformCompetition } from '@/utils/transformDataToHighcharts'

export default {
  name: 'competition-modal',
  components: {
    FullModalLayout,
    AppCompareHighcharts
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    toggle: {
      type: Boolean,
      default: false
    },
    horizontal: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      touch: false,
      data: null,
      colors: [],
      brands: [],
      openWhen: false,
      dateRange: {
        start: new Date(),
        end: new Date()
      }
    }
  },
  computed: {
    locale: function () {
      return this.$store.getters['auth/locale']
    },
    countryCode: function () {
      return this.$store.getters['filters/countryCode']
    },
    startDate: function () {
      return this.$store.getters['filters/startDate']
    },
    endDate: function () {
      return this.$store.getters['filters/endDate']
    },
    minDate: function () {
      return this.$store.getters['filters/general']?.dateRange.start
    },
    maxDate: function () {
      return this.$store.getters['filters/general']?.dateRange.end
    },
    when: function () {
      const start = moment(this.startDate, 'YYYY-MM-DD').format('D MMM, YY')
      const end = moment(this.endDate, 'YYYY-MM-DD').format('D MMM, YY')
      return [start, end]
    },
    momentPresets: function () {
      const presets = {
        primary: [],
        secondary: []
      }

      for (const preset of this.calendarPresets) {
        const p = {
          name: preset.name,
          start: moment(preset.start).format('D MMM, YY'),
          end: moment(preset.end).format('D MMM, YY'),
          disabled: !!preset.disabled,
          format: preset.format
        }

        if (preset.format === 'compact') {
          presets.secondary.push(p)
        } else {
          presets.primary.push(p)
        }
      }
      return presets
    },
    loading: function () {
      return this.$store.getters['app/loading']
    }
  },
  created: function () {
    this.dateRange.start = new Date(this.startDate)
    this.dateRange.end = new Date(this.endDate)
    this.calendarPresets = getCalendarPresets(this.minDate, this.maxDate)

    if (this.value && this.value.length) {
      this.downloadingData()
    }
  },
  methods: {
    transformDate: function (value, format = 'D MMM, YY') {
      return value ? moment(value, 'YYYY-MM-DD').format(format) : ''
    },
    toggleModal: function () {
      this.$emit('toggleModal')
    },
    toggleWhen: function () {
      this.openWhen = !this.openWhen
    },
    closeWhen: function () {
      this.openWhen = false
    },
    resetDates: function () {
      this.setDates(this.startDate, this.endDate)
    },
    setDates: function (start, end) {
      this.dateRange.start = new Date(start)
      this.dateRange.end = new Date(end)

      this.$refs.datepicker.updateValue(this.dateRange)
    },
    applyDateChanges: function () {
      const start = moment(this.dateRange.start).format('YYYY-MM-DD')
      const end = moment(this.dateRange.end).format('YYYY-MM-DD')
      this.$store.commit('filters/setStartDate', start)
      this.$store.commit('filters/setEndDate', end)
      this.toggleWhen()
    },
    downloadingData: async function () {
      try {
        this.$store.commit('app/setLoading', true)
        await this.fetchVisualRank()
      } catch (error) {
        this.doFeedback()
      } finally {
        this.touch = true
        this.$store.commit('app/setLoading', false)
      }
    },
    fetchVisualRank: async function () {
      const startDate = moment(this.startDate).format('DD-MM-YYYY')
      const endDate = moment(this.endDate).format('DD-MM-YYYY')
      const body = {
        listaPrecios: this.value
      }

      const data = await getCompetition(startDate, endDate, body)

      const countryCode = this.countryCode

      const transformData = transformCompetition(data, countryCode)

      let competitionData = transformData.charts
      const brands = transformData.brands
      const colors = getColors(brands.length)

      competitionData = this.setChartsColors(competitionData, brands, colors)
      competitionData[0].site = this.$t('app.priceEvolution')
      competitionData[1].site = this.$t('app.positionEvolution')

      this.colors = colors
      this.data = competitionData
      this.brands = brands
    },
    setChartsColors: function (charts, brands, colors) {
      for (const chart of charts) {
        const seriesBrands = chart.series.map(serie => serie.name)
        const c = this.getColorsByBrands(seriesBrands, brands, colors)
        chart.options.colors = c
      }

      return charts
    },
    getColorsByBrands: function (seriesBrands, brands, colors) {
      return seriesBrands.map(brand => {
        const position = brands.findIndex(b => b === brand)
        return colors[position]
      })
    }
  },
  watch: {
    toggle: function (value) {
      if (value) {
        this.downloadingData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vc-container {
  border: none !important;
}
.max-height-calendar {
  max-height: 400px;
}
.compact {
  display: inline-block;
}
.disabled {
  cursor: not-allowed;
  position: relative;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, .4);
  }
}
.pointer-disabled {
  pointer-events: none;
}
</style>
